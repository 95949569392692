function startOwlCarousel(alvo, options){
	if($(alvo).children().length > 1){
		$(alvo).owlCarousel(options);
	}else{
		$(alvo).addClass('owl-loaded');
	}
}

startOwlCarousel('#destaques',{
	responsive: {
		0 :{
			items: 1
		},
		600 : {
			items : 2,
			margin: 30
		},
		992 :{
			items : 4,
			margin: 30
		}
	},
	nav: true,
	navText: [
		'<i class="fa fa-chevron-left"></i>',
		'<i class="fa fa-chevron-right"></i>'
	]
});