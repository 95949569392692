const DOMINIO = window.location.protocol + '//' + window.location.host;

var Components = (function(){
	function Components(){
		this._Components = [];
	}

	Components.prototype.define = function (el, classe) {
		if(document.querySelector(el)){
			this._Components[el] = [];

			var els = document.querySelectorAll(el);

			for(var i=0;i<els.length;i++){
				this._Components[el].push(new classe(els[i]));
			}
		}
	}

	Components.prototype.log = function(){
		return console.log(this);
	}

	Components.prototype.clear = function(){
		return this._Components = [];
	}

	Components.prototype.delete = function(el){
		return delete this._Components[el];
	}

	return Components;
})();

const meusComponents = new Components();