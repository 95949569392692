$('[data-toggle="qtde"]').click(function(event) {
	var target = $(this).data('target');
	var fn = $(this).data('fn');
	var value = parseInt($(target).val());

	if(fn == 'plus'){
		$(target).val(value+1);
	}else if(fn == 'minus'){
		if(value > 1){
			$(target).val(value-1);
		}
	}
});
