$('[data-toggle="menu"]').click(function(event) {
	var alvo = $(this).data('target');

	$(alvo).toggleClass('open closed');
	$('body,html').toggleClass('lockscroll');
});

$('[data-toggle="cat"]').click(function(event) {
	var alvo = $(this).data('target');

	$(alvo).toggleClass('open');
});