$('[data-toggle="input-type"]').click(function(event) {
	var newType = $(this).data('type');
	var defaultType = $(this).data('default-type');
	var target = $($(this).data('target'));
	var type = target.attr('type');

	$(this).toggleClass('active');

	switch (type){
		case defaultType:
			target.attr('type',newType);
		break;
		case newType:
			target.attr('type', defaultType);
		break;
		default:
			console.log('Algo de errado não está muito certo')
		break;
	}

});