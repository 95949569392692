$(window).scroll(function(){
	if($(window).width() < 992){
		$('#nav-topo').affix({
			offset: {
				top: function(){
					return (this.top = $('.topo .bar-topo').outerHeight());
				}
			}
		});
	}
});